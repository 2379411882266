import { Suspense, lazy } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter } from 'react-router-dom';

import { Loader } from '@plot/ui';

import { TrackAreaReportViewed } from '@/lib/analytics/components';
import { AreaReportTrackerProvider } from '@/lib/analytics/contexts/area-report';
import { msalConfig } from '@/lib/msal';
import NotFoundPage from '@/pages/not-found/NotFound';

import {
  AppLayout,
  AuthLayout,
  PageLayout,
  WorkspaceLayout,
} from '@/components/layouts';
import { CadastralPlotLayout } from '@/components/layouts/workspace/cadastral-plot';

const AreaReport = lazy(() => import('../pages/area-report'));
// const Wizard = lazy(() => import('../pages/workspace/_wizard'));
const Settings = lazy(() => import('../pages/workspace/settings'));
const Members = lazy(() => import('../pages/workspace/members'));
// const Subscription = lazy(
//   () => import('../pages/workspace/_wizard/subscription')
// );
// const Invoices = lazy(() => import('../pages/workspace/_invoices'));
const Profile = lazy(() => import('../pages/account/profile'));
const Billing = lazy(() => import('../pages/account/billing'));
const Signin = lazy(() => import('../pages/auth/signin'));
const Signup = lazy(() => import('../pages/auth/signup'));
const Verify = lazy(() => import('../pages/auth/signup/verify'));
const ForgotPassword = lazy(() => import('../pages/auth/forgot-password'));
const ResetPassword = lazy(() => import('../pages/auth/reset-password'));
const NotSupported = lazy(() => import('../pages/auth/not-supported'));
const Error = lazy(() => import('../pages/workspace/error'));
const Welcome = lazy(() => import('../pages/workspace/welcome'));

const msalInstance = new PublicClientApplication(msalConfig);

const authRoutes = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: 'signin',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <GoogleOAuthProvider
                  clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <MsalProvider instance={msalInstance}>
                    <Signin />
                  </MsalProvider>
                </GoogleOAuthProvider>
              </Suspense>
            ),
          },
          {
            path: 'forgot-password',
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <ForgotPassword />
              </Suspense>
            ),
          },
          {
            path: 'reset-password/:user/:token',
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <ResetPassword />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'signup',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <GoogleOAuthProvider
                  clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <MsalProvider instance={msalInstance}>
                    <Signup />
                  </MsalProvider>
                </GoogleOAuthProvider>
              </Suspense>
            ),
          },
          {
            path: 'verify',
            element: (
              <Suspense fallback={<Loader backdrop cover />}>
                <Verify />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];

const workspaceSettingsRoutes = [
  {
    element: <PageLayout />,
    children: [
      {
        path: '/workspaces/:workspace?',
      },
      {
        path: '/profile',
        element: (
          <Suspense fallback={<Loader backdrop cover />}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: '/billing',
        element: (
          <Suspense fallback={<Loader backdrop cover />}>
            <Billing />
          </Suspense>
        ),
      },
      {
        path: '/workspaces/:workspace/settings',
        element: (
          <Suspense fallback={<Loader backdrop cover />}>
            <Settings />
          </Suspense>
        ),
      },
      {
        path: '/workspaces/:workspace/members',
        element: (
          <Suspense fallback={<Loader backdrop cover />}>
            <Members />
          </Suspense>
        ),
      },
    ],
  },
  //
  // {
  //   children: [
  //     {
  //       path: '/workspaces/:workspace/settings',
  //       element: (
  //         <Suspense fallback={<Loader backdrop cover />}>
  //           <Settings />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: '/workspaces/:workspace/members',
  //       element: (
  //         <Suspense fallback={<Loader backdrop cover />}>
  //           <Members />
  //         </Suspense>
  //       ),
  //     },
  // {
  //   path: 'subscription',
  //   element: (
  //     <Suspense fallback={<Loader backdrop cover />}>
  //       <Subscription showHeader />
  //     </Suspense>
  //   ),
  // },
  // {
  //   path: 'invoices',
  //   element: (
  //     <Suspense fallback={<Loader backdrop cover />}>
  //       <Invoices />
  //     </Suspense>
  //   ),
  // },
  //   ],
  // },
];

const otherRoutes = [
  {
    children: [
      {
        path: '/not-supported',
        element: <NotSupported />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

const workspaceRoutes = [
  {
    element: <WorkspaceLayout />,
    children: [
      {
        children: [
          {
            index: true,
            element: null,
          },
          ...workspaceSettingsRoutes,
          {
            element: <PageLayout />,
            path: '/workspaces/:workspace/area-reports',
            children: [
              {
                index: true,
                path: ':reportId?',
                element: (
                  <AreaReportTrackerProvider>
                    <TrackAreaReportViewed>
                      <Suspense fallback={<Loader backdrop cover />}>
                        <AreaReport />
                      </Suspense>
                    </TrackAreaReportViewed>
                  </AreaReportTrackerProvider>
                ),
              },
            ],
          },
          {
            children: [
              {
                path: 'workspaces/:workspace/cadastral-plots',
                element: <CadastralPlotLayout />,
                children: [
                  {
                    index: true,
                    path: ':cadastralPlotId',
                  },
                  {
                    path: ':cadastralPlotId/*',
                  },
                ],
              },
              {
                path: 'workspaces/:workspace/cadastral-units',
                element: <CadastralPlotLayout />,
                children: [
                  {
                    index: true,
                    path: ':cadastralUnitId',
                  },
                  {
                    path: ':cadastralUnitId/*',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [...workspaceRoutes, ...authRoutes, ...otherRoutes],
  },
]);

export default router;
