import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Anchor } from '@plot/ui';

import { MobileLayout } from '@/components/layouts';

export const NotSupported = () => {
  const tGlobal = useTranslations('Global');

  {
    tGlobal('notSupportedPage');
  }

  return (
    <MobileLayout>
      <div className="flex flex-col justify-center font-roobert">
        <img
          src="/not-supported.png"
          alt="Not supported"
          className="mx-auto flex h-44 w-44 flex-1"
        ></img>
        <div className="flex-1 px-4 text-center font-medium">
          <p className="mb-2 mt-4 text-[20px]">
            {tGlobal('notSupportedPageTitle')}
          </p>
          <p className="mb-8">{tGlobal('notSupportedPageDescription')}</p>
          <Anchor as={Link} to={'https://www.plot.ai/'} size="sm">
            {tGlobal('backToPlot')}
          </Anchor>
        </div>
      </div>
    </MobileLayout>
  );
};

export default NotSupported;
