import { useCallback, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import {
  Link,
  Navigate,
  Outlet,
  ScrollRestoration,
  matchPath,
  useLocation,
  type Location,
} from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { useDarkMode } from 'usehooks-ts';

import { Button, Loader } from '@plot/ui';

import { useProfile } from '@/lib/api';
import { useApp, useAuth, useWorkspace } from '@/lib/contexts';
import { NotSupported } from '@/pages/auth/not-supported';
import { Error } from '@/pages/workspace/error';
import { Welcome } from '@/pages/workspace/welcome';

import Header from '@/components/header';
import { WaitingList } from '@/components/waiting-list';
import { Workspace } from '@/components/workspace';

function PageWithHeader({ children }: { children?: React.ReactNode }) {
  const getKey = useCallback((location: Location) => location.key, []);
  const profile = useProfile();
  return (
    <>
      <Header />
      <main id="main" className="relative min-h-[calc(100vh-52px)]">
        <div>{children}</div>
      </main>
      {profile.isFetching && <Loader fullscreen backdrop />}
      <ScrollRestoration getKey={getKey} />
    </>
  );
}

export function WorkspaceLayout() {
  const { isDarkMode } = useDarkMode();
  const t = useTranslations('Global');
  const location = useLocation();
  const auth = useAuth();
  const profile = useProfile();

  const { loading, workspace, workspaces } = useWorkspace();

  useEffect(() => {
    if (!document) return;

    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    return () => {
      document.documentElement.classList.remove('dark');
    };
  }, [isDarkMode]);

  // Not authenticated
  if (!auth.token) {
    return <Navigate to={`/signin`} state={{ from: location }} replace />;
  }

  // Not supported device
  if (isMobileOnly) {
    return <NotSupported />;
  }

  // Profile not ready yet
  if (!profile.isFetched) {
    return <PageWithHeader />;
  }

  // Profile is on waiting list
  if (profile.data?.isOnWaitingList) {
    return (
      <PageWithHeader>
        <WaitingList />
        <Outlet />
      </PageWithHeader>
    );
  }

  // No workspace
  if (workspaces?.length === 0) {
    return (
      <PageWithHeader>
        <Error type="noWorkspace" />
        <Outlet />
      </PageWithHeader>
    );
  }

  // Workspace provided but not fetched (yet?)
  if (!workspace) {
    return (
      <PageWithHeader>
        {loading ? (
          <Loader fullscreen backdrop />
        ) : (
          <div className="relative flex h-screen items-center justify-center p-8">
            <div className="text-center">
              <h2 className="mb-6 text-4xl">
                <div className="text-primary">404</div>
                {t('workspaceNotFound')}
              </h2>
              <div className="mb-8 text-md">
                {t.rich('notFoundMessage', {
                  paragraph: (chunks) => (
                    <p className="mb-5 last:mb-0">{chunks}</p>
                  ),
                })}
              </div>
              <Button as={Link} to={'/'} variant="neutral">
                {t('home')}
              </Button>
            </div>
          </div>
        )}
        <Outlet />)
      </PageWithHeader>
    );
  }

  if (workspace?.isSuspended) {
    return (
      <PageWithHeader>
        <Error type="suspended" />
        <Outlet />
      </PageWithHeader>
    );
  }

  // Show setup wizard – but only for owner, allow other users to use app normally
  if (!workspace.isSetupComplete && workspace.role === 'owner') {
    return (
      <PageWithHeader>
        <Welcome />
        <Outlet />
      </PageWithHeader>
    );
  }

  return (
    <PageWithHeader>
      <Workspace />
      <Outlet />
    </PageWithHeader>
  );
}

export default WorkspaceLayout;
