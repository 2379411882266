import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { AreaReportDto, WorkspaceDto, type ApiError } from '@plot/plot-api';
import { Loader } from '@plot/ui';

import {
  useReport as useReportApi,
  useReports as useReportsApi,
  useWorkspace as useWorkspaceApi,
  useWorkspaces as useWorkspacesApi,
} from '@/lib/api';

import { useSection } from '../hooks';
import { useApp } from './app';
import { useAuth } from './auth';
import { useProfile } from './profile';

interface WorkspaceContextType {
  loading: boolean;
  workspaces: WorkspaceDto[] | undefined;
  workspace: WorkspaceDto | undefined;
  report: AreaReportDto | undefined;
  reports: AreaReportDto[] | undefined;
  setWorkspaceId: (workspaceId?: string) => void;
}

const WorkspaceContext = createContext<WorkspaceContextType | undefined>(
  undefined
);

type WorkspaceProviderProps = {
  children: React.ReactNode;
};

function getWorkspaceId(
  param: string | undefined,
  localStorage: string | undefined
) {
  if (param?.trim().length) return param;
  return localStorage;
}

function useNotFound(
  localStorage: string | undefined,
  workspaceId: string | undefined,
  error: ApiError | undefined
) {
  const happendOnLocalStorage = useRef(false);

  useEffect(() => {
    if (!error) {
      happendOnLocalStorage.current = false;
    }
  }, [error]);

  useEffect(() => {
    if (error?.status === 404) {
      happendOnLocalStorage.current = true;
    }
  }, [localStorage, workspaceId, error]);

  return [
    happendOnLocalStorage.current,
    !happendOnLocalStorage.current && error?.status === 404,
  ];
}

export function WorkspaceProvider({ children }: WorkspaceProviderProps) {
  const { dispatch } = useApp();
  const { authenticated } = useAuth();
  const profile = useProfile();
  const profileId = profile?.id;
  const section = useSection();
  const navigate = useNavigate();
  const [localStorage, setLocalStorage] = useLocalStorage<string | undefined>(
    `workspace-${String(profileId)}`,
    ''
  );
  const params = useParams<{ workspace: string; reportId: string }>();
  const workspaceId = getWorkspaceId(params.workspace, localStorage);

  const workspace = useWorkspaceApi({ workspaceId });

  const workspaces = useWorkspacesApi();

  const hasWorkspaces =
    !workspaces.isLoading && workspaces.status === 'success'
      ? (workspaces?.data?.count ?? 0) > 0
      : null;

  const isWorkspaceSuspended = workspace.data?.isSuspended;
  const reports = useReportsApi({
    workspaceId: isWorkspaceSuspended ? undefined : workspaceId,
  });
  const report = useReportApi({ workspaceId, reportId: params.reportId });

  const loading =
    workspace.isLoading ||
    workspaces.isLoading ||
    reports.isLoading ||
    report.isLoading;

  const error: ApiError =
    (workspace.error as ApiError) || (report.error as ApiError);

  const [resetLocalStorage] = useNotFound(localStorage, workspaceId, error);

  // We only set workspaceId if we have a profileId or the localhost key will be undefined
  const setWorkspaceId = useCallback(
    (id: string | undefined) => {
      if (profileId) {
        setLocalStorage(id ?? '');
      }
    },
    [profileId]
  );

  useEffect(() => {
    if (resetLocalStorage) {
      setWorkspaceId('');
    }
  }, [resetLocalStorage]);

  /**
   * If the workspaceId in localstorage doesent match the loaded workspace,
   * update local storage with id from loaded workspace
   */
  useEffect(() => {
    if (workspace.data?.id && localStorage !== workspace.data?.id) {
      setWorkspaceId(workspace.data?.id);
    }
  }, [workspace, localStorage]);

  /**
   * If we are on homepage and localstorage is empty, set workspaceId to loaded workspace or first workspace
   */
  useEffect(() => {
    if (
      profileId &&
      !workspaceId &&
      !workspace.isLoading &&
      !workspaces.isLoading
    ) {
      if (!section) {
        if (workspace.data?.id && workspace.data?.isSetupComplete) {
          setWorkspaceId(workspace.data?.id);
        } else if (workspaces.data?.count) {
          const completed = workspaces.data.results.find(
            ({ isSetupComplete }) => isSetupComplete
          );

          if (completed) {
            setWorkspaceId(completed?.id);
          } else {
            if (workspaces.data.results[0]) {
              setWorkspaceId(workspaces.data.results[0].id);
            } else {
              setWorkspaceId(undefined);
            }
          }
        }
      }
    }
  }, [
    section,
    profileId,
    workspace.isLoading,
    workspaces.isLoading,
    localStorage,
    workspace,
  ]);

  /**
   * If we are on homepage, navigate to current workspace
   */
  useEffect(() => {
    if (!loading) {
      if (!params.workspace && workspaceId && authenticated) {
        if (hasWorkspaces) {
          if (!section) {
            navigate(`/workspaces/${String(workspaceId)}`);
          }
        }
      }
    }
  }, [
    loading,
    hasWorkspaces,
    section,
    params.workspace,
    workspaceId,
    authenticated,
  ]);

  /**
   * Add current workspace to app state
   * */
  useEffect(() => {
    if (workspace.data && !isWorkspaceSuspended) {
      dispatch({
        type: 'setWorkspace',
        payload: workspace.data,
      });
    }
  }, [isWorkspaceSuspended]);

  return (
    <WorkspaceContext.Provider
      value={{
        loading,
        workspaces: workspaces.data?.results,
        workspace: workspace.data,
        reports: reports.data?.results,
        report: report.data,
        setWorkspaceId,
      }}
    >
      {children}
      {loading && <Loader cover backdrop />}
    </WorkspaceContext.Provider>
  );
}

export function useWorkspaceId() {
  const context = useContext(WorkspaceContext);

  if (context === undefined) {
    throw new Error('useWorkspaceId must be used within an WorkspaceContext');
  }

  return context.workspace?.id ?? '';
}

export function useWorkspace() {
  const context = useContext(WorkspaceContext);

  if (context === undefined) {
    throw new Error('useWorkspace must be used within an WorkspaceContext');
  }

  return context;
}

export function useReportId() {
  const context = useContext(WorkspaceContext);

  if (context === undefined) {
    throw new Error('useReportId must be used within an WorkspaceContext');
  }

  return context.report?.id;
}

export function useReportProps() {
  const context = useContext(WorkspaceContext);

  if (context === undefined) {
    throw new Error('useReportId must be used within an WorkspaceContext');
  }

  return {
    workspaceId: context.workspace?.id,
    reportId: context.report?.id,
  };
}

export function useReport() {
  const context = useContext(WorkspaceContext);

  if (context === undefined) {
    throw new Error('useReport must be used within an WorkspaceContext');
  }

  return context.report;
}

export function useReports() {
  const context = useContext(WorkspaceContext);

  if (context === undefined) {
    throw new Error('useReports must be used within an WorkspaceContext');
  }

  return context.reports ?? [];
}
