import { useMutation, useQueryClient } from 'react-query';

import {
  CompleteWorkspaceDto,
  WorkspaceDto,
  type CreateWorkspaceRequestDto,
  type ListWorkspaceDto,
  type PatchWorkspaceRequestDto,
} from '@plot/plot-api';

import { useApi } from '@/lib/api';
import { useAuth, useLocale } from '@/lib/contexts';

export function useWorkspaceMutations() {
  const queryClient = useQueryClient();
  const { api } = useApi();
  const { authenticated, token } = useAuth();
  const { locale } = useLocale();

  return {
    create: useMutation({
      mutationFn: (data: CreateWorkspaceRequestDto) =>
        api.workspace.create(data),

      onSettled: async () => {
        await queryClient.invalidateQueries('workspace');
        await queryClient.invalidateQueries('workspaces');
      },
    }),

    patch: useMutation({
      mutationFn: (data) => api.workspace.patch(data),
      // When mutate is called:
      onMutate: async (data: PatchWorkspaceRequestDto) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries('workspace');
        await queryClient.cancelQueries('workspaces');

        const prevPost = queryClient.getQueryData<WorkspaceDto>([
          'workspace',
          data.id,
          authenticated,
          token?.access,
          locale,
        ]);

        // Optimistically update to the new value
        if (prevPost) {
          queryClient.setQueryData<WorkspaceDto>('workspaces', {
            ...prevPost,
            ...data,
          });
        }

        // Snapshot the previous value
        const prevList = queryClient.getQueryData<ListWorkspaceDto>([
          'workspaces',
          authenticated,
          token?.access,
          locale,
        ]);

        // Optimistically update to the new value
        if (prevList) {
          queryClient.setQueryData<ListWorkspaceDto>('workspaces', {
            ...prevList,
            results: prevList.results.map((ws) =>
              ws.id === data.id ? { ...ws, ...data } : ws
            ),
          });
        }

        return { prevList, prevPost };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.prevPost) {
          queryClient.setQueryData<WorkspaceDto>(
            ['workspaces', authenticated, token?.access, locale],
            context.prevPost
          );
        }

        if (context?.prevList) {
          queryClient.setQueryData<ListWorkspaceDto>(
            ['workspaces', authenticated, token?.access, locale],
            context.prevList
          );
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries('workspace');
        await queryClient.invalidateQueries('workspaces');
      },
    }),

    remove: useMutation({
      mutationFn: (id: string) => api.workspace.remove(id),

      onSettled: async () => {
        await queryClient.invalidateQueries('workspace');
        await queryClient.invalidateQueries('workspaces');
      },
    }),

    leave: useMutation({
      mutationFn: (id) => api.workspace.leave(id),
      // When mutate is called:
      onMutate: async (id: string) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries('workspaces');

        // Snapshot the previous value
        const prev = queryClient.getQueryData<ListWorkspaceDto>([
          'workspaces',
          authenticated,
          token?.access,
          locale,
        ]);

        // Optimistically update to the new value
        if (prev) {
          queryClient.setQueryData<ListWorkspaceDto>(
            ['workspaces', authenticated, token?.access, locale],
            {
              ...prev,
              results: prev.results.filter((workspace) => workspace.id !== id),
            }
          );
        }

        return { prev };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.prev) {
          queryClient.setQueryData<ListWorkspaceDto>(
            ['workspaces', authenticated, token?.access, locale],
            context.prev
          );
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries('workspace');
        await queryClient.invalidateQueries('workspaces');
      },
    }),

    completeSetup: useMutation({
      mutationFn: ({ id, data }: { id: string; data: CompleteWorkspaceDto }) =>
        api.workspace.completeSetup(id, data),
      // When mutate is called:
      onMutate: async ({ id }: { id: string; data: CompleteWorkspaceDto }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries('workspaces');

        const prevPost = queryClient.getQueryData<WorkspaceDto>([
          'workspace',
          id,
          authenticated,
          token?.access,
          locale,
        ]);

        // Optimistically update to the new value
        if (prevPost) {
          queryClient.setQueryData<WorkspaceDto>('workspaces', {
            ...prevPost,
            isSetupComplete: true,
          });
        }

        // Snapshot the previous value
        const prevList = queryClient.getQueryData<ListWorkspaceDto>([
          'workspaces',
          authenticated,
          token?.access,
          locale,
        ]);

        // Optimistically update to the new value
        if (prevList) {
          queryClient.setQueryData<ListWorkspaceDto>('workspaces', {
            ...prevList,
            results: prevList.results.map((ws) =>
              ws.id === id ? { ...ws, isSetupComplete: true } : ws
            ),
          });
        }

        return { prevPost, prevList };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.prevPost) {
          queryClient.setQueryData<WorkspaceDto>(
            ['workspaces', authenticated, token?.access, locale],
            context.prevPost
          );
        }

        if (context?.prevList) {
          queryClient.setQueryData<ListWorkspaceDto>(
            ['workspaces', authenticated, token?.access, locale],
            context.prevList
          );
        }
      },

      onSettled: async () => {
        await queryClient.invalidateQueries('workspace');
        await queryClient.invalidateQueries('workspaces');
      },
    }),
  };
}
