import { zodResolver } from '@hookform/resolvers/zod';
import { RocketLaunch, Trash } from '@phosphor-icons/react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast/headless';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { z } from 'zod';

import { Alert, Button } from '@plot/ui';

import { useWorkspaceMutations } from '@/lib/api';
import { useLoading, useLocale, useWorkspaceId } from '@/lib/contexts';

const schema = z.object({
  members: z
    .array(
      z.object({
        invitedEmail: z
          .string()
          .min(5, { message: 'Email is required' })
          .email({ message: 'Invalid email' }),
      })
    )
    .min(1, { message: 'At least one email is required' }),
});

type Schema = FieldValues & z.infer<typeof schema>;

export const Welcome = () => {
  const t = useTranslations('Manage.Welcome');
  const workspaceId = useWorkspaceId();
  const { completeSetup } = useWorkspaceMutations();
  const { errorMap } = useLocale();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  z.setErrorMap(errorMap);

  const onComplete = () => {
    if (workspaceId) {
      toast.custom(<Alert type="success" message={t('complete')} />, {
        duration: 5000,
      });
      navigate(`/workspaces/${String(workspaceId)}`);
    }
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    values: {
      members: [{ invitedEmail: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray<Schema>({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'members', // unique name for your Field Array
  });

  async function onSkip() {
    try {
      setLoading(true);
      await completeSetup.mutateAsync({
        id: workspaceId,
        data: { invitedEmails: [] },
      });
      setLoading(false);
      onComplete?.();
    } catch (err) {
      setLoading(false);
      toast.custom(<Alert type="error" message={(err as Error).message} />, {
        duration: 5000,
      });
    }
  }

  async function onSubmit(data: Schema) {
    try {
      const invitedEmails = data.members.map((member) => member.invitedEmail);

      await completeSetup.mutateAsync({
        id: workspaceId,
        data: { invitedEmails },
      });
      onComplete?.();
    } catch (err) {
      toast.custom(<Alert type="error" message={(err as Error).message} />, {
        duration: 5000,
      });
    }
  }

  return (
    <div className="m-auto flex h-screen max-w-7xl flex-col items-center justify-center">
      <div>
        <header className="mb-5 flex flex-col items-center justify-center gap-2">
          <RocketLaunch
            size={64}
            className="mb-6 text-primary dark:text-primary-45"
            weight="thin"
          />
          <h1 className="hd-xl mb-6">
            {t.rich('title', {
              plotTeam: (chunks) => (
                <span className="text-primary dark:text-primary-45">
                  {chunks}
                </span>
              ),
            })}
          </h1>
        </header>
        <p className="mb-6">{t('description')}</p>
        <form
          className="flex flex-col gap-y-3"
          onSubmit={handleSubmit(onSubmit)}
          aria-live="polite"
          autoComplete="off"
        >
          <ul className="flex flex-col gap-y-3">
            {fields.map((item, index) => (
              <li key={item.id}>
                <div className="flex items-center gap-x-2">
                  <div className="flex-1">
                    <input
                      className="active:state-active-light active:dark:state-active-dark peer body-base h-12 w-full rounded-lg border-1 border-neutral-20 bg-neutral-0 pl-3 pr-3 outline-none focus:state-focus-light  focus:dark:state-focus-dark hover:border-neutral-60 disabled:opacity-50 dark:border-neutral-50 dark:bg-neutral-70 [.card_&]:border-neutral-40 [.card_&]:bg-neutral-10 dark:[.card_&]:border-neutral-30 dark:[.card_&]:bg-neutral-60"
                      aria-required="true"
                      aria-describedby={`members.${index}.error`}
                      placeholder={t('placeholder')}
                      type="text"
                      {...register(`members.${index}.invitedEmail`)}
                    />
                  </div>
                  {index > 0 ? (
                    <button
                      className="text-error"
                      type="button"
                      onClick={() => remove(index)}
                      aria-label={t('remove')}
                    >
                      <Trash size={20} />
                    </button>
                  ) : (
                    <Trash
                      className="cursor-not-allowed text-neutral-20 dark:text-neutral-50"
                      size={20}
                    />
                  )}
                </div>

                {errors?.members?.[index]?.invitedEmail?.message && (
                  <Alert
                    id={`members.${index}.error`}
                    type="error"
                    role="alert"
                    message={t(
                      `errors.${
                        errors?.members?.[index]?.invitedEmail
                          ?.message as string
                      }`
                    )}
                    inline={true}
                    className="pt-2"
                  />
                )}
              </li>
            ))}
          </ul>
          {errors?.members?.message && (
            <Alert
              type="error"
              role="alert"
              message={t(`errors.${errors?.members?.message}`)}
              inline={true}
              className="pt-2"
            />
          )}
          <div>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                append('');
              }}
            >
              {t('add')}
            </Button>
          </div>
          <div className="mt-6 flex justify-center gap-x-4">
            <Button variant="ghost" type="button" onClick={onSkip}>
              {t('skip')}
            </Button>
            <Button>{t('submit')}</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Welcome;
