import { Warning } from '@phosphor-icons/react';
import { useTranslations } from 'use-intl';

interface IError {
  type: 'suspended' | 'inactive' | 'noWorkspace';
}

export const Error = ({ type }: IError) => {
  const t = {
    suspended: useTranslations('Manage.Error.Suspended'),
    inactive: useTranslations('Manage.Error.Inactive'),
    noWorkspace: useTranslations('Manage.Error.NoWorkspace'),
  }[type];

  return (
    <div className="relative flex h-screen items-center justify-center p-8">
      <div className="text-center">
        <div className="mb-6 flex flex-col items-center text-lg">
          <Warning size={64} className="mb-6 text-primary" weight="light" />
          {t('title')}
        </div>
        <div className="mb-8 text-sm">
          {t.rich('footer', {
            paragraph: (chunks) => <p className="mb-5 last:mb-0">{chunks}</p>,
          })}
        </div>
      </div>
    </div>
  );
};

export default Error;
