import { ProfileDto, WorkspaceDto } from '@plot/plot-api';

import {
  BaseBoundData,
  BaseTracker,
} from '@/lib/analytics/trackers/BaseTracker';
import { MapMode } from '@/lib/types';

export declare interface WorkspaceTrackerBoundData extends BaseBoundData {
  user: ProfileDto;
  workspace: WorkspaceDto;
  mapModeName: MapMode;
}

function valueOrFirstItem(itemId: string | string[] | null) {
  if (Array.isArray(itemId)) {
    return itemId.length ? itemId[0] : null;
  }
  return itemId;
}

export class WorkspaceTracker<
  BoundData extends WorkspaceTrackerBoundData = WorkspaceTrackerBoundData
> extends BaseTracker<BoundData> {
  /*
   Every action of logged-in user takes place inside workspace, so in fact this is the base tracker class.
   */
  track(eventName: string, eventProperties: object = {}) {
    super.track(eventName, {
      workspaceId: this.boundData.workspace.id,
      mapModeName: this.boundData.mapModeName || null,
      ...eventProperties,
    });
  }

  workspaceSelected(workspaceId: string) {
    /*
     Selected new workspaceId that override one stored in boundData
     */
    this.track('WorkspaceSelected', { workspaceId });
  }

  mapModeSelected(mapModeSelected: MapMode) {
    /*
     Selected new mapModeName that override one stored in boundData
     */
    this.track('MapModeSelected', { mapModeSelected });
  }

  mapFeatureClicked(eventProperties: object = {}) {
    this.track('MapFeatureClicked', eventProperties);
  }

  mapFeatureDetailsViewed(eventProperties: object = {}) {
    this.track('MapFeatureDetailsViewed', eventProperties);
  }

  mapCoordinatesClicked(eventProperties: object = {}) {
    this.track('MapCoordinatesClicked', eventProperties);
  }

  mapFilterChanged(eventProperties: object = {}) {
    this.track('MapFilterChanged', eventProperties);
  }

  plotMapFeatureClicked(plotId: string, zoomLevel: number) {
    this.mapFeatureClicked({ plotId, featureType: 'plot', zoomLevel });
  }

  projectMapFeatureClicked(projectId: string, zoomLevel: number) {
    this.mapFeatureClicked({ projectId, featureType: 'project', zoomLevel });
  }

  existingUnitMapFeatureClicked(unitId: string, zoomLevel: number) {
    this.mapFeatureClicked({ unitId, featureType: 'existing-unit', zoomLevel });
  }

  projectMapFeatureDetailsViewed(projectId: string) {
    this.mapFeatureDetailsViewed({ projectId, featureType: 'project' });
  }

  existingUnitMapFeatureDetailsViewed(unitId: string) {
    this.mapFeatureDetailsViewed({ unitId, featureType: 'existing-unit' });
  }

  plotMapFeatureDetailsViewed(unitId: string) {
    this.mapFeatureDetailsViewed({ unitId, featureType: 'plot' });
  }

  geonorgePlanDetailsViewed(lngLat: mapboxgl.LngLat) {
    this.track('GeonorgePlanDetailsViewed', lngLat);
  }

  geodataFeatureDetailsViewed(eventProperties: object = {}) {
    this.track('GeodataFeatureDetailsViewed', eventProperties);
  }

  externalListingLinkClicked(eventProperties: object = {}) {
    this.track('ExternalListingLinkClicked', eventProperties);
  }

  externalGeonorgePlanLinkClicked(lngLat: mapboxgl.LngLat) {
    this.track('ExternalGeonorgePlanLinkClicked', lngLat);
  }

  projectDetailsViewed(
    projectId: string,
    listIndex: number | undefined = undefined,
    listLength: number | undefined = undefined
  ) {
    this.geodataFeatureDetailsViewed({
      projectId,
      featureType: 'project',
      listIndex,
      listLength,
    });
  }

  projectStageDetailsViewed(
    projectId: string,
    projectStageId: string,
    listIndex: number | undefined = undefined,
    listLength: number | undefined = undefined
  ) {
    this.geodataFeatureDetailsViewed({
      projectId,
      projectStageId,
      featureType: 'project-stage',
      listIndex,
      listLength,
    });
  }

  projectStageExternalListingDetailsViewed(
    projectId: string,
    projectStageId: string,
    listIndex: number | undefined = undefined,
    listLength: number | undefined = undefined
  ) {
    this.externalListingLinkClicked({
      projectId,
      projectStageId,
      featureType: 'project-stage',
      listIndex,
      listLength,
    });
  }

  projectUnitDetailsViewed(
    projectId: string,
    projectStageId: string,
    unitId: string,
    listIndex: number | undefined = undefined,
    listLength: number | undefined = undefined
  ) {
    this.geodataFeatureDetailsViewed({
      projectId,
      projectStageId,
      unitId,
      featureType: 'project-unit',
      listIndex,
      listLength,
    });
  }

  projectUnitExternalListingLinkClicked(
    projectId: string,
    projectStageId: string,
    unitId: string,
    listIndex: number | undefined = undefined,
    listLength: number | undefined = undefined
  ) {
    this.externalListingLinkClicked({
      projectId,
      projectStageId,
      unitId,
      featureType: 'project-unit',
      listIndex,
      listLength,
    });
  }

  existingUnitDetailsViewed(
    unitId: string,
    listIndex: number | undefined = undefined,
    listLength: number | undefined = undefined
  ) {
    this.geodataFeatureDetailsViewed({
      unitId,
      featureType: 'existing-unit',
      listIndex,
      listLength,
    });
  }

  existingUnitExternalListingLinkClicked(
    unitId: string,
    listIndex: number | undefined = undefined,
    listLength: number | undefined = undefined
  ) {
    this.externalListingLinkClicked({
      unitId,
      featureType: 'existing-unit',
      listIndex,
      listLength,
    });
  }

  plotDetailsViewed(
    plotId: string,
    listIndex: number | undefined = undefined,
    listLength: number | undefined = undefined
  ) {
    this.geodataFeatureDetailsViewed({
      plotId,
      featureType: 'plot',
      listIndex,
      listLength,
    });
  }

  downloadXlsx(eventProperties: object = {}) {
    this.track('DownloadXlsxClicked', eventProperties);
  }
}
