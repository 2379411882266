import { Suspense, lazy } from 'react';
import * as Sentry from '@sentry/react';
import { MapProvider } from 'react-map-gl';
import { Outlet } from 'react-router-dom';

import { WorkspaceTrackerProvider } from '@/lib/analytics/contexts';
import {
  AppProvider,
  AuthProvider,
  ProfileProvider,
  WizardProvider,
  WorkspaceProvider,
} from '@/lib/contexts';
import { Error } from '@/pages/error';

const Modals = lazy(() => import('../../modals'));
const Toaster = lazy(() => import('../../toaster'));

export function AppLayout() {
  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      <AuthProvider>
        <ProfileProvider>
          <AppProvider>
            <WorkspaceProvider>
              <WizardProvider>
                <MapProvider>
                  <WorkspaceTrackerProvider>
                    <Outlet />
                    <Suspense fallback={null}>
                      <Modals />
                    </Suspense>
                    <Suspense fallback={null}>
                      <Toaster />
                    </Suspense>
                  </WorkspaceTrackerProvider>
                </MapProvider>
              </WizardProvider>
            </WorkspaceProvider>
          </AppProvider>
        </ProfileProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
}

export default AppLayout;
