import { useEffect } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { useDarkMode } from 'usehooks-ts';

import { useProfile } from '@/lib/api';
import { Modal, useApp, useAuth } from '@/lib/contexts';

export function CadastralPlotLayout() {
  const { isDarkMode } = useDarkMode();
  const t = useTranslations('Global');
  const location = useLocation();
  const auth = useAuth();
  const profile = useProfile();
  const { dispatch } = useApp();

  useEffect(() => {
    dispatch({
      type: 'openModal',
      payload: {
        id: Modal.PLOT_DETAILS,
      },
    });
    return () => dispatch({ type: 'closeModal' });
  }, [location, dispatch]);

  return <Outlet />;
}

export default CadastralPlotLayout;
