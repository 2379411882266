import { Suspense, lazy } from 'react';

import { Loader } from '@plot/ui';

import { useWorkspace } from '@/lib/contexts';
import Maintenance from '@/pages/maintenance';

const PlotMap = lazy(() => import('../../features/plot-map'));
const Drawers = lazy(() => import('../drawers'));

export function Workspace() {
  const { workspace } = useWorkspace();

  return (
    <>
      <Suspense fallback={<Loader backdrop cover />}>
        {workspace?.appFlags?.geodataMaintenance ? (
          <Maintenance size={'xl'} />
        ) : (
          <PlotMap />
        )}
      </Suspense>
      <Suspense fallback={null}>
        <Drawers />
      </Suspense>
    </>
  );
}

export default Workspace;
