import { CirclesThree } from '@phosphor-icons/react';
import { format, startOfDay, startOfMonth, subMonths } from 'date-fns';
import { current, produce } from 'immer';
import { create } from 'zustand';

import {
  FloorRemoveType,
  OwnershipType,
  PropertyType,
  UseType,
} from '@plot/plot-api';

import { Dates } from '@/components/area-report/filter-control/component/date-range';
import { RangeType } from '@/components/area-report/filter-control/component/range';

import {
  IMapFilters,
  IMapState,
  MapControlType,
  MapDataSelected,
  MapFilterType,
  MapMode,
} from '../types';

export const initialMapFilters: IMapFilters = {
  newProjectActive: {
    id: 'newProjectActive',
    label: ``,
    apiParam: 'include_datasets',
    type: MapFilterType.TOGGLE,
    values: true,
  },
  newProjectDataSelected: {
    id: 'newProjectDataSelected',
    label: '',
    icon: <CirclesThree size={20} className="mt-[1px]" />,
    type: MapFilterType.SELECT_AND_DATE,
    dataSelected: MapDataSelected.PROJECTS_ON_THE_MARKET,
    apiParams: {
      [MapDataSelected.PROJECTS_ON_THE_MARKET]: [
        'new_project__active_before',
        'new_project__active_after',
      ],
      [MapDataSelected.UNITS_FOR_SALE_TODAY]: [
        'new_project__unit__active_before',
        'new_project__unit__active_after',
      ],
      [MapDataSelected.UNITS_LISTED]: [
        'new_project__unit__listed_at_before',
        'new_project__unit__listed_at_after',
      ],
      [MapDataSelected.UNITS_SOLD]: [
        'new_project__unit__sold_at_before',
        'new_project__unit__sold_at_after',
      ],
    },
    dataValues: [
      MapDataSelected.PROJECTS_ON_THE_MARKET,
      MapDataSelected.UNITS_FOR_SALE_TODAY,
      MapDataSelected.UNITS_LISTED,
      MapDataSelected.UNITS_SOLD,
    ],
    dateValues: {
      minDate: startOfMonth(subMonths(startOfMonth(new Date()), 3)),
      maxDate: startOfDay(new Date()),
    },
    minDate: new Date('2020-03-01'),
  },
  newProjectUnitPropertyType: {
    id: 'newProjectUnitPropertyType',
    apiParam: 'new_project__unit__property_type',
    label: `label.propertyType`,
    type: MapFilterType.CHECKBOX,
    values: [
      PropertyType.APARTMENT,
      PropertyType.SEMIDETACHED,
      PropertyType.TOWNHOUSE,
      PropertyType.DETACHED,
      PropertyType.CABIN,
    ].map((value) => ({
      id: value,
      label: `filterPropertyType.${value}`,
      checked: false,
    })),
  },
  newProjectUnitOwnershipType: {
    id: 'newProjectUnitOwnershipType',
    apiParam: 'new_project__unit__ownership_type',
    label: 'label.ownershipType',
    type: MapFilterType.CHECKBOX,
    values: [OwnershipType.OWNED, OwnershipType.COOPERATIVE].map((value) => ({
      id: value,
      label: `filterOwnershipType.${value}`,
      checked: false,
    })),
  },
  newProjectUnitSellingPrice: {
    id: 'newProjectUnitSellingPrice',
    label: `label.price`,
    apiParam: [
      'new_project__unit__selling_price_min',
      'new_project__unit__selling_price_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.price`,
    values: [null, null],
  },
  newProjectUnitSqmPrice: {
    id: 'newProjectUnitSqmPrice',
    label: `label.sqmPrice`,
    apiParam: [
      'new_project__unit__sqm_price_min',
      'new_project__unit__sqm_price_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.sqmPrice`,
    values: [null, null],
  },
  newProjectUsableSize: {
    id: 'newProjectUsableSize',
    label: `label.usableArea`,
    apiParam: [
      'new_project__unit__usable_area_min',
      'new_project__unit__usable_area_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.usableArea`,
    values: [null, null],
  },
  newProjectUnitDaysOnMarketMin: {
    id: 'newProjectUnitDaysOnMarketMin',
    label: `label.daysOnMarket`,
    apiParam: [
      'new_project__unit__days_on_market_min',
      'new_project__unit__days_on_market_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.daysOnMarket`,
    values: [null, null],
  },
  newProjectUnitRooms: {
    id: 'newProjectUnitRooms',
    label: `label.rooms`,
    apiParam: ['new_project__unit__rooms_min', 'new_project__unit__rooms_max'],
    type: MapFilterType.RANGE,
    unit: `unit.rooms`,
    values: [null, null],
  },
  newProjectFloors: {
    id: 'newProjectFloors',
    apiParam: [
      'new_project__unit__is_bottom_floor',
      'new_project__unit__is_top_floor',
    ],
    label: `label.floors`,
    tooltip: 'tooltip.floors',
    type: MapFilterType.CHECKBOX_FLAG,
    values: [FloorRemoveType.GROUND_FLOOR, FloorRemoveType.TOP_FLOOR].map(
      (value) => ({
        id: value,
        label: `filterFloorRemoveType.${value}`,
        checked: false,
      })
    ),
  },
  usedUnitActive: {
    id: 'usedUnitActive',
    label: ``,
    apiParam: 'include_datasets',
    type: MapFilterType.TOGGLE,
    values: true,
  },
  usedUnitDataSelected: {
    id: 'usedUnitDataSelected',
    label: ``,
    icon: <CirclesThree size={20} className="mt-[1px]" />,
    type: MapFilterType.SELECT_AND_DATE,
    dataSelected: MapDataSelected.UNITS_ON_THE_MARKET,
    apiParams: {
      [MapDataSelected.UNITS_ON_THE_MARKET]: [
        'used_unit__active_before',
        'used_unit__active_after',
      ],
      [MapDataSelected.UNITS_LISTED]: [
        'used_unit__listed_at_before',
        'used_unit__listed_at_after',
      ],
      [MapDataSelected.UNITS_SOLD]: [
        'used_unit__sold_at_before',
        'used_unit__sold_at_after',
      ],
    },
    dataValues: [
      MapDataSelected.UNITS_ON_THE_MARKET,
      MapDataSelected.UNITS_LISTED,
      MapDataSelected.UNITS_SOLD,
    ],
    dateValues: {
      minDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      maxDate: startOfDay(new Date()),
    },
    minDate: new Date('2020-03-01'),
  },
  usedUnitUseType: {
    id: 'usedUnitUseType',
    apiParam: 'used_unit__use_type',
    label: 'label.useType',
    type: MapFilterType.CHECKBOX,
    description: 'description.useType',
    values: [
      {
        id: UseType.RESIDENTIAL,
        label: `filterUseType.${UseType.RESIDENTIAL}`,
        checked: true,
      },
      {
        id: UseType.RECREATIONAL,
        label: `filterUseType.${UseType.RECREATIONAL}`,
        checked: false,
      },
    ],
  },
  usedUnitPropertyType: {
    id: 'usedUnitPropertyType',
    apiParam: 'used_unit__property_type',
    label: `label.propertyType`,
    type: MapFilterType.CHECKBOX,
    values: [
      PropertyType.APARTMENT,
      PropertyType.SEMIDETACHED,
      PropertyType.TOWNHOUSE,
      PropertyType.DETACHED,
      PropertyType.CABIN,
    ].map((value) => ({
      id: value,
      label: `filterPropertyType.${value}`,
      checked: false,
    })),
  },
  usedUnitOwnershipType: {
    id: 'usedUnitOwnershipType',
    apiParam: 'used_unit__ownership_type',
    label: 'label.ownershipType',
    type: MapFilterType.CHECKBOX,
    values: [
      OwnershipType.OWNED,
      OwnershipType.COOPERATIVE,
      OwnershipType.STOCK,
      OwnershipType.BOND,
    ].map((value) => ({
      id: value,
      label: `filterOwnershipType.${value}`,
      checked: false,
    })),
  },

  usedUnitSellingPrice: {
    id: 'usedUnitSellingPrice',
    label: `label.price`,
    apiParam: ['used_unit__selling_price_min', 'used_unit__selling_price_max'],
    type: MapFilterType.RANGE,
    unit: `unit.price`,
    values: [null, null],
  },
  usedUnitSqmPrice: {
    id: 'usedUnitSqmPrice',
    label: `label.sqmPrice`,
    apiParam: ['used_unit__sqm_price_min', 'used_unit__sqm_price_max'],
    type: MapFilterType.RANGE,
    unit: `unit.sqmPrice`,
    values: [null, null],
  },
  usedUnitUsableArea: {
    id: 'usedUnitUsableArea',
    label: `label.usableArea`,
    apiParam: ['used_unit__usable_area_min', 'used_unit__usable_area_max'],
    type: MapFilterType.RANGE,
    unit: `unit.usableArea`,
    values: [null, null],
  },
  usedUnitDaysOnMarket: {
    id: 'usedUnitDaysOnMarket',
    label: `label.daysOnMarket`,
    apiParam: [
      'used_unit__days_on_market_min',
      'used_unit__days_on_market_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.daysOnMarket`,
    values: [null, null],
  },
  usedUnitRooms: {
    id: 'usedUnitRooms',
    label: `label.rooms`,
    apiParam: ['used_unit__rooms_min', 'used_unit__rooms_max'],
    type: MapFilterType.RANGE,
    unit: `unit.rooms`,
    values: [null, null],
  },
  usedUnitConstructionYear: {
    id: 'usedUnitConstructionYear',
    label: `label.constructionYear`,
    apiParam: [
      'used_unit__construction_year_min',
      'used_unit__construction_year_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.years`,
    values: [null, null],
  },
};

export const useMapStore = create<IMapState>((set) => ({
  mapControl: 'none',
  setMapControl: (mapControl: MapControlType) => set({ mapControl }),
  mapMode: MapMode.BROWSING,
  setMapMode: (mapMode: MapMode) => set({ mapMode }),
  mapFilters: initialMapFilters,
  onFilterChange: undefined,
  setOnFilterChange: (fn) => set({ onFilterChange: fn }),
  isSecondaryFiltersSectionVisible: {
    newProject: false,
    usedUnit: false,
  },
  toggleSecondaryFilters: (section) => {
    set((state) => ({
      isSecondaryFiltersSectionVisible: {
        ...state.isSecondaryFiltersSectionVisible,
        [section]: !state.isSecondaryFiltersSectionVisible[section],
      },
    }));
  },
  closeSecondaryFilters: (section) => {
    set((state) => ({
      isSecondaryFiltersSectionVisible: {
        ...state.isSecondaryFiltersSectionVisible,
        [section]: false,
      },
    }));
  },

  updateToggleMapFilter: (filterId: string, value: boolean) => {
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];
        if (targetFilter?.type === MapFilterType.TOGGLE) {
          targetFilter.values = value;
          if (draft.onFilterChange) {
            draft.onFilterChange(filterId, value, draft.mapFilters);
          }
        }
      })
    );
  },
  updateCheckboxMapFilter: (
    filterId: string,
    valueId: string,
    checked: boolean
  ) => {
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];
        if (
          (targetFilter?.type === MapFilterType.CHECKBOX ||
            targetFilter?.type === MapFilterType.CHECKBOX_FLAG) &&
          targetFilter.values
        ) {
          const valueToUpdate = targetFilter.values.find(
            (value) => value.id === valueId
          );
          if (valueToUpdate) {
            valueToUpdate.checked = checked;
          }

          if (draft.onFilterChange) {
            draft.onFilterChange(
              filterId,
              current(targetFilter.values)
                .filter((value) => value.checked)
                .map((value) => value.id),
              current(draft.mapFilters)
            );
          }
        }
      })
    );
  },
  updateDateRangeMapFilter: (filterId: string, dates: Dates) => {
    const [start, end] = dates;
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];
        if (
          targetFilter?.type === MapFilterType.DATE_PICKER ||
          targetFilter?.type === MapFilterType.DATE_SELECTOR
        ) {
          targetFilter.values.minDate = start;
          targetFilter.values.maxDate = end;

          if (start && end) {
            if (draft.onFilterChange) {
              draft.onFilterChange(
                filterId,
                [format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')],
                current(draft.mapFilters)
              );
            }
          }
        }
      })
    );
  },
  updateRangeMapFilter: (filterId: string, value: RangeType) => {
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];
        if (targetFilter?.type === MapFilterType.RANGE && targetFilter.values) {
          targetFilter.values = value;
          if (value[0] || value[1]) {
            if (draft.onFilterChange) {
              draft.onFilterChange(filterId, value, current(draft.mapFilters));
            }
          }
        }
      })
    );
  },
  updateSelectAndDateMapFilter: ({
    filterId,
    dataValue,
    dateValues,
  }: {
    filterId: string;
    dataValue?: string;
    dateValues?: Dates;
  }) => {
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];

        if (targetFilter?.type !== MapFilterType.SELECT_AND_DATE) return;

        // Update dataSelected
        if (dataValue) {
          targetFilter.dataSelected = dataValue;
        }

        // Handle dateValues: If switching to 'UNITS_FOR_SALE_TODAY', set to today; otherwise, reset or update dateValues
        if (
          targetFilter.dataSelected === MapDataSelected.UNITS_FOR_SALE_TODAY
        ) {
          targetFilter.dateValues = {
            minDate: startOfDay(new Date()),
            maxDate: startOfDay(new Date()),
          };
        } else if (dateValues) {
          const [newMinDate, newMaxDate] = dateValues;
          targetFilter.dateValues = {
            minDate: newMinDate,
            maxDate: newMaxDate,
          };
        } else {
          // Reset to default range when switching away from 'UNITS_FOR_SALE_TODAY'
          targetFilter.dateValues = {
            minDate: startOfMonth(subMonths(new Date(), 3)), // Reset to last 3 months, or use a default
            maxDate: startOfDay(new Date()),
          };
        }

        // Trigger onFilterChange with updated dataSelected and formatted dateValues
        if (draft.onFilterChange) {
          draft.onFilterChange(
            filterId,
            {
              dataSelected: targetFilter.dataSelected,
              dateValues: {
                minDate: targetFilter.dateValues.minDate
                  ? format(targetFilter.dateValues.minDate, 'yyyy-MM-dd')
                  : null,
                maxDate: targetFilter.dateValues.maxDate
                  ? format(targetFilter.dateValues.maxDate, 'yyyy-MM-dd')
                  : null,
              },
            },
            draft.mapFilters
          );
        }
      })
    );
  },

  resetFilter: (filterId: string) => {
    set(
      produce((draft: IMapState) => {
        draft.mapFilters[filterId] = initialMapFilters[filterId];
        if (draft.onFilterChange) {
          draft.onFilterChange(filterId, 'reset', current(draft.mapFilters));
        }
      })
    );
  },
  resetFilters: () => {
    set(
      produce((draft: IMapState) => {
        draft.mapFilters = initialMapFilters;
        if (draft.onFilterChange) {
          draft.onFilterChange('general', 'resetAll', initialMapFilters);
        }
      })
    );
  },
}));

export default useMapStore;
