import { useMemo, useState } from 'react';
import { animated, useTransition } from '@react-spring/web';
import { Outlet, useLocation, useOutlet } from 'react-router-dom';

import { TransitionStateProvider } from '@/lib/contexts';
import { useSection } from '@/lib/hooks';

function AnimatedOutlet() {
  const o = useOutlet();
  const [outlet] = useState(o);
  return outlet;
}

function PageTransition({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const section = useSection();

  const context = useMemo(() => {
    switch (section) {
      case '':
      case undefined:
      case 'workspaces':
        return 'root';

      default:
        return section;
    }
  }, [section]);

  const transition = useTransition(context, {
    initial: {
      // opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    from: {
      // opacity: 0,
      transform: (location.state as { back: boolean } | null)?.back
        ? 'translate3d(100%, 0, 0)'
        : 'translate3d(-100%, 0, 0)',
    },
    enter: {
      // opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    leave: {
      // opacity: 0,
      transform: (location.state as { back: boolean } | null)?.back
        ? 'translate3d(-100%, 0, 0)'
        : 'translate3d(100%, 0, 0)',
    },
  });

  // Alternative transition
  // const transition = useTransition(context, {
  //   initial: { opacity: 1 },
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  // });

  return transition((style, item, transitionState) =>
    item && item !== 'root' ? (
      <animated.div
        key={item}
        style={style}
        className="absolute bottom-0 left-0 top-0 z-10 h-full w-full overflow-y-auto bg-neutral-0 dark:bg-neutral-70"
      >
        <TransitionStateProvider transitionState={transitionState}>
          {children}
        </TransitionStateProvider>
      </animated.div>
    ) : (
      children
    )
  );
}

export function PageLayout() {
  return (
    <PageTransition>
      <AnimatedOutlet />
    </PageTransition>
  );
}

export default PageLayout;
